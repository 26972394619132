import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountSelectors } from '../account';
import { ProjectSelectors } from '../project';
import { ProjectMemberSelectors } from '../project-member';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { SiteMember } from './models';
import { adapter, LoadingStatus, State } from './site-member.store';

export const selectSiteMemberEntry = createFeatureSelector<State>(StateEntry.SiteMember);

export const selectLoadingStatus = createSelector(
    selectSiteMemberEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSiteMemberEntry);

export const selectCurrentSiteMembers = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    ProjectMemberSelectors.selectAll,
    (siteMembers, siteId, projectMembers) =>
        siteMembers
            .filter((sm) => sm.siteId === siteId)
            .map((sm) => {
                const projectMembership = projectMembers.filter((pm) => pm.siteMemberId === sm.id);
                const mergedSiteMember: SiteMember = { ...sm, projectMembership };
                return mergedSiteMember;
            })
);
export const selectCurrentUserAsSiteMember = createSelector(
    selectCurrentSiteMembers,
    AccountSelectors.selectUserAccount,
    (siteMembers, userAccount) => siteMembers.find((member) => member.accountId === userAccount.id)
);

export const selectSiteMemberById = (id: string) =>
    createSelector(selectCurrentSiteMembers, (resources) => resources.find((r) => r.id === id));

export const selectCurrentProjectMembers = createSelector(
    selectCurrentSiteMembers,
    ProjectSelectors.selectCurrentProject,
    (resources, project) =>
        resources.filter((resource) =>
            resource.projectMembership?.some((m) => m.projectId === project?.id)
        )
);

export const selectNotCurrentProjectMembers = createSelector(
    selectCurrentSiteMembers,
    ProjectSelectors.selectCurrentProjectId,
    (resources, projectId) =>
        resources.filter(
            (resource) =>
                !resource.projectMembership?.length ||
                !resource.projectMembership?.some((c) => c.projectId === projectId)
        )
);
