import { MatDialogConfig } from '@angular/material/dialog';
import { ContextMenuItem } from 'app/shared-ui/dumb-components/context-menu/context-menu.directive';
import { Project, ProjectBudgetEntity } from '../store/project';
import { ProjectStatusReportBudget } from '../store/project-status-report';
import { AsyncUpdateStatus } from '../store/shared/async-update-status.store';

export enum CtxColor {
    Default = 'Default',
    LightGray = 'LightGray',
    Gray = 'Gray',
    Red = 'Red',
    Blue = 'Blue',
    LightBlue = 'LightBlue',
    Green = 'Green',
    Yellow = 'Yellow',
    Orange = 'Orange',
    Purple = 'Purple',
    Brown = 'Brown',
    Black = 'Black',
}

export const ctxColorToHexMap: Record<CtxColor, string> = {
    [CtxColor.Default]: '#ebebeb',
    [CtxColor.LightGray]: '#ebebeb',
    [CtxColor.Gray]: '#5d5d5d',
    [CtxColor.Red]: '#f44336',
    [CtxColor.Blue]: '#1e88e5',
    [CtxColor.LightBlue]: '#89cff0',
    [CtxColor.Green]: '#3ec256',
    [CtxColor.Yellow]: '#ffcc33',
    [CtxColor.Orange]: '#e65100',
    [CtxColor.Purple]: '#ad67e6',
    [CtxColor.Brown]: '#a87447',
    [CtxColor.Black]: '#141414',
};

export const ctxColorText: Record<CtxColor, string> = {
    [CtxColor.Default]: 'Default',
    [CtxColor.LightGray]: 'Light gray',
    [CtxColor.Gray]: 'Gray',
    [CtxColor.Red]: 'Red',
    [CtxColor.Blue]: 'Blue',
    [CtxColor.LightBlue]: 'Light blue',
    [CtxColor.Green]: 'Green',
    [CtxColor.Yellow]: 'Yellow',
    [CtxColor.Orange]: 'Orange',
    [CtxColor.Purple]: 'Purple',
    [CtxColor.Brown]: 'Brown',
    [CtxColor.Black]: 'Black',
};

export interface EntitySubmission {
    submitted?: boolean;
    date?: Date;
    siteMemberId?: string;
    rejected?: boolean;
}

export interface CtxMember {
    id: string;
    externalName?: string;
    siteMemberId?: string;
    avatarColor?: string;
}

export interface ContextMenuItemSelectedEvent {
    id: string;
    item: ContextMenuItem;
}

export function getRandomBrightColor(): string {
    const getColorNumber = () => Math.floor(Math.random() * 200) + 50;
    return `rgb(${getColorNumber()},${getColorNumber()},${getColorNumber()})`;
}

export interface ImportSystemTemplatePayload {
    siteId: string;
    systemTemplateId: string;
}

export const documentDialogDefaultConfig: MatDialogConfig = {
    height: '80%',
    width: '1000px',
    maxWidth: '97%',
    position: null,
    autoFocus: false,
};

export enum Priority {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical',
}

export const priorityColorMap: Record<Priority, CtxColor> = {
    [Priority.Low]: CtxColor.Green,
    [Priority.Medium]: CtxColor.Yellow,
    [Priority.High]: CtxColor.Orange,
    [Priority.Critical]: CtxColor.Red,
};

export interface LoadingStatusBySiteId {
    loadingStatusBySiteId: Record<string, AsyncUpdateStatus>;
}

export interface LoadingStatusByProjectId {
    loadingStatusByProjectId: Record<string, AsyncUpdateStatus>;
}

export interface LoadingStatusByProgramId {
    loadingStatusByProgramId: Record<string, AsyncUpdateStatus>;
}

export interface ProjectBudgetEntityBase {
    total: any;
    capex: any;
    opex: any;
}

export interface ProjectBudgetBase {
    plannedCost: any;
    actualToDate: any;
    currentFYForecast: any;
    currentFYActuals: any;
}

const projectBudgetBaseDefault: Required<ProjectBudgetBase> = {
    plannedCost: null,
    actualToDate: null,
    currentFYForecast: null,
    currentFYActuals: null,
};

export const projectBudgetKeys: (keyof ProjectBudgetBase)[] = Object.keys(
    projectBudgetBaseDefault
) as (keyof ProjectBudgetBase)[];

export function getProjectBudgetBaseEnabledKeys(budgetFiscalsEnabled: boolean) {
    const enabledKeysMap: Record<keyof ProjectBudgetBase, boolean> = {
        ['plannedCost']: true,
        ['actualToDate']: true,
        ['currentFYForecast']: budgetFiscalsEnabled,
        ['currentFYActuals']: budgetFiscalsEnabled,
    };
    return projectBudgetKeys.filter((key) => enabledKeysMap[key]);
}

export enum DocumentExportType {
    Pdf = 'Pdf',
    Source = 'Source',
}

export enum EntityLevel {
    Site = 'Site',
    Project = 'Project',
    Program = 'Program',
    System = 'System',
}

export interface EntityScope {
    id: string;
    level: EntityLevel;
}

export interface CurrentFyBudget {
    value?: number;
    autocalculated?: boolean;
}

export interface LoadingStatusByContainerId {
    loadingStatusByContainerId: Record<string, AsyncUpdateStatus>;
}

export function getProjectBudgetsSum(projects: Project[]): ProjectStatusReportBudget {
    const emptyBudgetBase: Required<ProjectBudgetBase> = {
        plannedCost: { value: {} },
        actualToDate: { value: {} },
        currentFYActuals: { value: {} },
        currentFYForecast: { value: {} },
    };
    const budgetEntityKeys: (keyof ProjectBudgetEntity)[] = ['total', 'capex', 'opex'];
    const budget: ProjectStatusReportBudget = {
        approvedBudget: projects.reduce(
            (acc, curr) => acc + (curr.liveBudget?.approvedBudget ?? 0),
            0
        ),
        approvedCurrentFY: projects.reduce(
            (acc, curr) => acc + (curr.liveBudget?.approvedCurrentFY ?? 0),
            0
        ),
        ...emptyBudgetBase,
    };
    Object.keys(emptyBudgetBase).forEach((budgetKey: keyof ProjectBudgetBase) =>
        budgetEntityKeys.forEach(
            (entityKey) =>
                (budget[budgetKey].value[entityKey] = projects
                    .map((prj) => prj.liveBudget?.[budgetKey]?.[entityKey] || 0)
                    .reduce((acc, curr) => acc + curr, 0))
        )
    );
    return budget;
}

export function getLightenHexColor(color: CtxColor, magnitude = 80): string {
    const hexColor = ctxColorToHexMap[color].replace(`#`, ``);
    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
        return hexColor;
    }
}

export function getCurrentFyBudgetValue(
    currentFyBudget: CurrentFyBudget,
    projects: Project[]
): number {
    return currentFyBudget?.autocalculated
        ? getProjectsCurrentFyBudget(projects)
        : currentFyBudget?.value ?? 0;
}

export function getProjectsCurrentFyBudget(projects: Project[]): number {
    return projects
        .map((p) => p.budget?.approvedCurrentFY ?? 0)
        .reduce((acc, curr) => acc + curr, 0);
}

export const currentFyBudgetAutocalculatedCheckboxTooltip = 'Auto-calculated = Sum of all projects';
