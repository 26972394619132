import { ProjectFieldItem } from 'app/core/store/project-field';
import { SiteMember } from 'app/core/store/resource';
import { BehaviorSubject } from 'rxjs';
import { AddObjectMeta } from '../table/gl-change';
import { ColumnShape } from './column-shape';
import { TableShape } from './table-shape';

export interface GlMemberRenderData {
    allSiteMembers: SiteMember[];
    me$: BehaviorSubject<SiteMember>;
}

export interface GlMemberRendererSettings {
    multiple?: boolean;
    allowManual?: boolean;
}

export interface GlStatusRenderEditorData {
    allStatuses: ProjectFieldItem[];

    glStyle?: TextStyle;
}

// TODO: move other table shape updatable props here
export type TableShapeUpdate = Pick<TableShape, 'glStyle'>;

// TODO: move other column shape updatable props here
export type ColumnShapeUpdate = Pick<ColumnShape, 'glId' | 'glStyle'>;

// TODO: move other row updatable props here
export type GlRowDataUpdate = Pick<GlRowData, 'id' | 'glStyleMap' | 'dataObjectKey'>;

export interface ObjectChange {
    objectId: string;
    property: string;
    oldValue: any;
    newValue: any;
}

export interface RowChange {
    rowId: string;
    property: string;
    oldValue: any;
    newValue: any;
}

export interface GridChangeEvent {
    source: string;
    objectChanges?: ObjectChange[];
    rowChanges?: RowChange[];
    orderedRowIds?: string[];
    oldOrderedRowIds?: string[];
    removedRowIds?: string[];
    removedObjectIds?: string[];
    collapsedRowIds?: string[];
    orderedColumnIds?: number[];
    oldOrderedColumnIds?: number[];
    insertedRows?: GlRowData[];
    insertedObjects?: AddObjectMeta[];
    tableShapeUpdate?: TableShapeUpdate;
    columnShapeUpdates?: ColumnShapeUpdate[];
    rowUpdates?: GlRowDataUpdate[];
}

export class GlRowData {
    // FIXME: rename id to rowId
    id: string;
    dataObjectKey?: string;
    valueMap?: RowRecordValueMap;
    glStyleMap?: TextStyleValueMap;
}

export interface TextStyleValueMap {
    [columnId: number]: TextStyle;
}

export interface RowRecordValueMap {
    [columnId: number]: string;
}

export interface HandsontableRowData extends GlRowData {
    dataObject?: any;
    scheduleData?: ScheduleExtensionRowData;
    treeData?: TreeExtensionRowData;
    filterData?: FilterExtensionRowData;
}

export interface ScheduleExtensionRowData {
    textDependencies?: string;
}

export interface TreeExtensionRowData {
    nodeId: string | number;
    parentNodeId?: string | number | null;

    // Used to hide collapsed rows
    visible: boolean;
}

export interface FilterExtensionRowData {
    matches: boolean;
}

export interface TextStyle {
    fontFamily?: string;
    fontSize?: number;
    bold?: boolean;
    italic?: boolean;
    strikeThrough?: boolean;
    textColor?: string;
    fillColor?: string;
    horAlign?: TextAlignMode;
}

export enum TextAlignMode {
    Left = 'Left',
    Center = 'Center',
    Right = 'Right',
}

export interface ReminderAddEvent {
    objectId: string;
    date: Date;
}

export type CustomFilterVisualizationAdapter = (prop: string | number, filterValue: any) => string;
