import { Model } from '../../shared/models/base.model';
import { RouteBrick } from './../../../../route-brick';

export class CtxNotification extends Model {
    id: string;
    type: NotificationType;
    level: NotificationLevel;
    originObjectId?: string;
    originObjectType?: NotificationOriginObjectType;
    originProjectId?: string;
    siteId?: string;
    date: Date;
    read?: boolean;
    message: string;
    routes?: string[];

    constructor(init?: Partial<CtxNotification>) {
        super();
        Object.assign(this, init);
    }
}
export enum NotificationType {
    InvitedRegisteredToSite = 'InvitedRegisteredToSite',
    InvitedUnregisteredToSite = 'InvitedUnregisteredToSite',
    InvitedRegisteredToProject = 'InvitedRegisteredToProject',
    InvitedUnRegisteredToProject = 'InvitedUnRegisteredToProject',
    AssignedToAction = 'AssignedToAction',
    AssignedToIssue = 'AssignedToIssue',
    AssignedToTask = 'AssignedToTask',
    AssignedToRisk = 'AssignedToRisk',
    ReceivedApprovalRequest = 'ReceivedApprovalRequest',
    ReminderTriggered = 'ReminderTriggered',
    AssignedRegisteredPMToProject = 'AssignedRegisteredPMToProject',
    AssignedUnRegisteredPMToProject = 'AssignedUnRegisteredPMToProject',
    TrialEnd = 'TrialEnd',
    CommentMention = 'CommentMention',
    Share = 'Share',
}

export function getNotificationRoute(notification: CtxNotification): string[] {
    const originProjectBaseRoute = [RouteBrick.Projects, notification.originProjectId];
    const raidBaseRoute = [...originProjectBaseRoute, RouteBrick.Raid];
    const map: Record<string, string[]> = {
        [NotificationType.AssignedToAction]: [
            ...raidBaseRoute,
            RouteBrick.Actions,
            notification.originObjectId,
        ],
        [NotificationType.AssignedToRisk]: [
            ...raidBaseRoute,
            RouteBrick.Risks,
            notification.originObjectId,
        ],
        [NotificationType.AssignedToIssue]: [
            ...raidBaseRoute,
            RouteBrick.Issues,
            notification.originObjectId,
        ],
        [NotificationType.AssignedToTask]: [
            ...originProjectBaseRoute,
            RouteBrick.Agile,
            RouteBrick.Backlog,
        ],
        [NotificationType.AssignedRegisteredPMToProject]: [
            RouteBrick.Projects,
            notification.originObjectId,
        ],
        [NotificationType.InvitedRegisteredToProject]: [
            RouteBrick.Projects,
            notification.originObjectId,
        ],
    };
    return map[notification.type];
}

export enum NotificationLevel {
    Account = 'Account',
    Site = 'Site',
}

export const notificationTitleMap: Record<NotificationType, string> = {
    [NotificationType.InvitedUnregisteredToSite]: 'Site invitation',
    [NotificationType.InvitedRegisteredToSite]: 'Site invitation',
    [NotificationType.InvitedRegisteredToProject]: 'Project invitation',
    [NotificationType.InvitedUnRegisteredToProject]: 'Project invitation',
    [NotificationType.AssignedToAction]: 'Assignment',
    [NotificationType.AssignedToIssue]: 'Assignment',
    [NotificationType.AssignedToTask]: 'Assignment',
    [NotificationType.AssignedToRisk]: 'Assignment',
    [NotificationType.ReceivedApprovalRequest]: 'New Approval',
    [NotificationType.ReminderTriggered]: 'Reminder',
    [NotificationType.AssignedRegisteredPMToProject]: 'Assignment',
    [NotificationType.AssignedUnRegisteredPMToProject]: 'Assignment',
    [NotificationType.TrialEnd]: 'Trial End',
    [NotificationType.CommentMention]: 'Mention',
    [NotificationType.Share]: 'Sharing',
};

export let shownNotificationIds: string[] = [];

export const notificationMatIconNameMap: Record<NotificationType, string> = {
    [NotificationType.ReminderTriggered]: 'notifications',
    [NotificationType.InvitedUnregisteredToSite]: 'person_add',
    [NotificationType.InvitedRegisteredToSite]: 'person_add',
    [NotificationType.InvitedRegisteredToProject]: 'person_add',
    [NotificationType.InvitedUnRegisteredToProject]: 'person_add',
    [NotificationType.AssignedToTask]: 'assignment_ind',
    [NotificationType.AssignedToIssue]: 'assignment_ind',
    [NotificationType.AssignedToAction]: 'assignment_ind',
    [NotificationType.AssignedToRisk]: 'assignment_ind',
    [NotificationType.ReceivedApprovalRequest]: 'how_to_reg',
    [NotificationType.AssignedRegisteredPMToProject]: 'person_add',
    [NotificationType.AssignedUnRegisteredPMToProject]: 'person_add',
    [NotificationType.TrialEnd]: 'feedback',
    [NotificationType.CommentMention]: '3p',
    [NotificationType.Share]: 'share',
};

export interface SendNotificationPayload {
    siteMemberIds: string[];
    type: NotificationType;
    siteId: string;
    originObjectId: string;
    originObjectType?: NotificationOriginObjectType;
    originProjectId?: string;
    level: NotificationLevel;
    routes?: string[];
    message: string;
}

export enum NotificationOriginObjectType {
    Risk = 'Risk',
    Issue = 'Issue',
    Decision = 'Decision',
    Action = 'Action',
    Schedule = 'Schedule',
    BacklogTask = 'BacklogTask',
    ChangeRequest = 'ChangeRequest',
    Gating = 'Gating',
    Document = 'Document',
}

export function getDocumentShareRoutes(documentId: string): string[] {
    return [RouteBrick.Documents, documentId];
}
