import { CtxColor, CurrentFyBudget } from 'app/core/models/shared-models';
import { SiteEntityState } from 'app/core/models/site-entity.model';
import { Model } from '../shared/models/base.model';

export class Program extends Model {
    // All initialized fields are required
    id = '';
    name = '';
    siteId = '';
    isArchived = false;
    isDeleted = false;
    number = 'P-001';
    state = SiteEntityState.Open;
    color: CtxColor = CtxColor.Blue;

    // Not initialized fields are optional
    description?: string;
    dateArchived: Date;
    dateDeleted: Date;
    objective: string;
    goals: string;
    sponsors: string;
    constraints: string;
    overallStatus: OverallStatus;
    managerIds: string[];
    currentFYBudget?: CurrentFyBudget;

    constructor(init?: Partial<Program>) {
        super();
        Object.assign(this, init);
    }
}

export enum OverallStatus {
    Green = 'Green',
    Yellow = 'Yellow',
    Red = 'Red',
}
