import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import * as DocumentActions from './document.actions';
import { adapter, FileNodeSystemTag, initialState } from './document.store';
import { FileNode } from './models';

export const reducer = createReducer(
    initialState,

    on(DocumentActions.getDocumentByIdSuccess, (state, payload) =>
        adapter.addOne(payload.document, adapter.removeOne(payload.document.id, state))
    ),

    on(DocumentActions.loadCompletixDocumentTemplates, (state, action) => ({
        ...state,
        completixDocumentTempalatesLoading: true,
    })),

    on(DocumentActions.loadCompletixDocumentTemplatesSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.documents,
            adapter.removeMany(
                payload.documents.map((d) => d.id),
                state
            )
        ),
        completixDocumentTempalatesLoading: false,
    })),

    on(DocumentActions.loadCompletixDocumentTemplatesFail, (state) => ({
        ...state,
        completixDocumentTempalatesLoading: false,
    })),

    on(DocumentActions.loadCompletixDocumentTemplatesBySiteId, (state, action) => ({
        ...state,
        completixDocumentTempalatesLoading: true,
    })),

    on(DocumentActions.loadCompletixDocumentTemplatesBySiteIdSuccess, (state, { documents }) => ({
        ...adapter.addMany(
            documents,
            adapter.removeMany(
                documents.map((d) => d.id),
                state
            )
        ),
        completixDocumentTempalatesLoading: false,
    })),

    on(DocumentActions.loadCompletixDocumentTemplatesBySiteIdFail, (state, action) => ({
        ...state,
        completixDocumentTempalatesLoading: false,
    })),

    on(DocumentActions.loadDocumentsByProject, (state, action) => {
        return {
            ...state,
            projectDocumentsLoadingStatusMap: {
                ...state.projectDocumentsLoadingStatusMap,
                [action.payload.projectId]: {
                    ...state.projectDocumentsLoadingStatusMap[action.payload.projectId],
                    serverRequestInProgress: true,
                },
            },
        };
    }),

    on(DocumentActions.loadDocumentsByProjectSuccess, (state, payload) => {
        const removeIds = [
            ...Object.values(state.entities)
                .filter((doc) => doc.projectId === payload.projectId)
                .map((d) => d.id),
            ...payload.documents.map((d) => d.id),
        ];

        return {
            ...adapter.addMany(payload.documents, adapter.removeMany(removeIds, state)),
            projectDocumentsLoadingStatusMap: {
                ...state.projectDocumentsLoadingStatusMap,
                [payload.projectId]: { serverRequestInProgress: false, loaded: true },
            },
        };
    }),

    on(DocumentActions.loadDocumentsByProjectFail, (state, payload) => {
        return {
            ...state,
            projectDocumentsLoadingStatusMap: {
                ...state.projectDocumentsLoadingStatusMap,
                [payload.projectId]: {
                    ...state.projectDocumentsLoadingStatusMap[payload.projectId],
                    serverRequestInProgress: false,
                },
            },
        };
    }),

    on(DocumentActions.loadDocumentsByProgram, (state, action) => ({
        ...state,
        programDocumentsLoadingStatusMap: {
            ...state.programDocumentsLoadingStatusMap,
            [action.payload.programId]: {
                ...state.programDocumentsLoadingStatusMap[action.payload.programId],
                serverRequestInProgress: true,
            },
        },
    })),

    on(DocumentActions.loadDocumentsByProgramSuccess, (state, payload) => {
        const removeIds = [
            ...Object.values(state.entities)
                .filter((doc) => doc.programId === payload.programId)
                .map((d) => d.id),
            ...payload.documents.map((d) => d.id),
        ];
        return {
            ...adapter.addMany(payload.documents, adapter.removeMany(removeIds, state)),
            programDocumentsLoadingStatusMap: {
                ...state.programDocumentsLoadingStatusMap,
                [payload.programId]: { serverRequestInProgress: false, loaded: true },
            },
        };
    }),

    on(DocumentActions.loadDocumentsByProgramFail, (state, payload) => {
        return {
            ...state,
            programDocumentsLoadingStatusMap: {
                ...state.programDocumentsLoadingStatusMap,
                [payload.programId]: {
                    ...state.programDocumentsLoadingStatusMap[payload.programId],
                    serverRequestInProgress: false,
                },
            },
        };
    }),

    on(DocumentActions.loadDocumentsBySite, (state, action) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(DocumentActions.loadDocumentsBySiteSuccess, (state, payload) => {
        const removeIds = Object.values(state.entities)
            .filter((doc) => doc.siteId === payload.siteId && !doc.systemTag)
            .map((d) => d.id);

        return {
            ...adapter.addMany(payload.documents, adapter.removeMany(removeIds, state)),
            serverRequestInProgress: false,
            loaded: true,
        };
    }),

    on(DocumentActions.loadDocumentsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(DocumentActions.loadDocumentChangeRequestFormBySiteId, (state) => ({
        ...state,
        changeRequestFormLoadingStatus: {
            ...state.changeRequestFormLoadingStatus,
            loadingFormBySiteId: true,
        },
    })),

    on(DocumentActions.loadDocumentChangeRequestFormBySiteIdSuccess, (state, payload) => {
        const currentFormDoc = Object.values(state.entities).find(
            (d) =>
                d.siteId === payload.document.siteId &&
                d.systemTag === FileNodeSystemTag.ChangeRequestForm
        );
        return {
            ...adapter.addOne(payload.document, adapter.removeOne(currentFormDoc?.id, state)),
            changeRequestFormLoadingStatus: {
                ...state.changeRequestFormLoadingStatus,
                loadingFormBySiteId: false,
            },
        };
    }),

    on(DocumentActions.loadDocumentChangeRequestFormBySiteIdFail, (state) => ({
        ...state,
        changeRequestFormLoadingStatus: {
            ...state.changeRequestFormLoadingStatus,
            loadingFormBySiteId: false,
        },
    })),

    on(DocumentActions.loadCompletixDocumentChangeRequestForm, (state) => ({
        ...state,
        changeRequestFormLoadingStatus: {
            ...state.changeRequestFormLoadingStatus,
            loadingCompletixForm: true,
        },
    })),

    on(DocumentActions.loadCompletixDocumentChangeRequestFormSuccess, (state, payload) => {
        const currentFormDoc = Object.values(state.entities).find(
            (d) => d.isCompletixTemplate && d.systemTag === FileNodeSystemTag.ChangeRequestForm
        );
        return {
            ...adapter.addOne(payload.document, adapter.removeOne(currentFormDoc?.id, state)),
            changeRequestFormLoadingStatus: {
                ...state.changeRequestFormLoadingStatus,
                loadingCompletixForm: false,
            },
        };
    }),

    on(DocumentActions.loadCompletixDocumentChangeRequestFormFail, (state) => ({
        ...state,
        changeRequestFormLoadingStatus: {
            ...state.changeRequestFormLoadingStatus,
            loadingCompletixForm: false,
        },
    })),

    on(DocumentActions.loadCompletixDocumentChangeRequestFormBySiteId, (state) => ({
        ...state,
        changeRequestFormLoadingStatus: {
            ...state.changeRequestFormLoadingStatus,
            loadingCompletixFormBySiteId: true,
        },
    })),

    on(DocumentActions.loadCompletixDocumentChangeRequestFormBySiteIdSuccess, (state, payload) => {
        const currentFormDoc = Object.values(state.entities).find(
            (d) => d.isCompletixTemplate && d.systemTag === FileNodeSystemTag.ChangeRequestForm
        );
        return {
            ...adapter.addOne(payload.document, adapter.removeOne(currentFormDoc?.id, state)),
            changeRequestFormLoadingStatus: {
                ...state.changeRequestFormLoadingStatus,
                loadingCompletixFormBySiteId: false,
            },
        };
    }),

    on(DocumentActions.loadCompletixDocumentChangeRequestFormBySiteIdFail, (state) => ({
        ...state,
        changeRequestFormLoadingStatus: {
            ...state.changeRequestFormLoadingStatus,
            loadingCompletixFormBySiteId: false,
        },
    })),

    on(DocumentActions.getDocumentByIdSuccess, (state, payload) =>
        adapter.addOne(payload.document, adapter.removeOne(payload.document.id, state))
    ),

    on(DocumentActions.addDocumentSuccess, (state, payload) => {
        return adapter.addOne(payload.document, state);
    }),

    on(DocumentActions.addManyDocumentsSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.documents,
            adapter.removeMany(
                payload.documents.map((d) => d.id),
                state
            )
        ),
    })),

    on(DocumentActions.getManyDocumentsSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.documents,
            adapter.removeMany(
                payload.documents.map((d) => d.id),
                state
            )
        ),
    })),

    on(DocumentActions.patchDocument, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedDocument = getPatched(state.entities[action.payload.id], action.payload.patch);
        const documentUpdate: Update<FileNode> = {
            id: patchedDocument.id,
            changes: patchedDocument,
        };
        return adapter.updateOne(documentUpdate, state);
    }),

    on(DocumentActions.patchDocumentSuccess, (state, payload) => {
        const document = state.entities[payload.id];
        if (!document) {
            console.error(`The document with id ${payload.id} isn't found`);
            return state;
        }
        const patchedDocument = getPatched(document, payload.patch);
        const documentUpdate: Update<FileNode> = {
            id: patchedDocument.id,
            changes: patchedDocument,
        };
        return adapter.updateOne(documentUpdate, state);
    }),

    on(DocumentActions.patchDocumentFail, (state) => ({ ...state })),

    on(DocumentActions.deleteDocument, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.documentId, state);
    }),

    on(DocumentActions.deleteDocumentSuccess, (state, payload) => ({
        ...adapter.removeMany(payload.ids, state),
    })),

    on(DocumentActions.copyDocumentsSuccess, (state, payload) => ({
        ...adapter.addMany(payload.documents, adapter.removeAll(state)),
    })),

    on(DocumentActions.setCopyCutBuffer, (state, payload) => ({
        ...state,
        copyCutOperation: {
            ids: payload.ids,
            operationType: payload.operationType,
        },
    })),

    on(DocumentActions.clearCopyCutBuffer, (state) => ({
        ...state,
        copyCutOperation: null,
    })),

    on(DocumentActions.changeCurrentOpenedNodeId, (state, payload) => ({
        ...state,
        currentOpenedNodeId: payload.documentId,
    })),

    on(DocumentActions.toggleAutoSave, (state, payload) => ({
        ...state,
        fileAutoSaveEnabled: payload.status,
    })),

    on(DocumentActions.getDocumentPreview, (state, action) => ({
        ...state,
        previewBlobLoadingStatusMap: {
            ...state.previewBlobLoadingStatusMap,
            [action.payload.documentId]: true,
        },
    })),

    on(DocumentActions.getDocumentPreviewSuccess, (state, payload) => {
        return {
            ...state,
            previewBlobMap: { ...state.previewBlobMap, [payload.documentId]: payload.previewBlob },
            previewBlobLoadingStatusMap: {
                ...state.previewBlobLoadingStatusMap,
                [payload.documentId]: false,
            },
        };
    }),

    on(DocumentActions.getDocumentPreviewFail, (state, payload) => ({
        ...state,
        previewBlobLoadingStatusMap: {
            ...state.previewBlobLoadingStatusMap,
            [payload.documentId]: false,
        },
    }))
);
