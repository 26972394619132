import { CtxColor, CurrentFyBudget } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export class Portfolio extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('siteId')
    siteId: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('projectIds')
    projectIds: string[] = [];

    @JsonProperty('color')
    color: CtxColor = CtxColor.Blue;

    @JsonProperty('currentFYBudget')
    currentFYBudget: CurrentFyBudget;

    constructor(init?: Partial<Portfolio>) {
        super();
        Object.assign(this, init);
    }
}

export interface PortfolioCreateOptions {
    siteId: string;
    name: string;
}

export interface PortfolioUpdateOptions {
    name?: string;
    pejectIds?: string[];
}
