import { Action } from '@ngrx/store';
import { ActionActions } from 'app/core/store/actions';
import { AllocationActions } from 'app/core/store/allocation';
import { ApprovalRequestActions } from 'app/core/store/approval-request';
import { BacklogTaskActions } from 'app/core/store/backlog-task';
import { BudgetTableActions } from 'app/core/store/budget-table';
import { CalendarActions } from 'app/core/store/calendar';
import { ChangeRequestActions } from 'app/core/store/change-request';
import { CommentActions } from 'app/core/store/comment';
import { CommunicationActions } from 'app/core/store/communication';
import { DecisionActions } from 'app/core/store/decision';
import { DocumentActions } from 'app/core/store/document';
import { ExecutiveDashboardActions } from 'app/core/store/executive-dashboard';
import { FolderActions } from 'app/core/store/folder';
import { GlTableSettingsActions } from 'app/core/store/gl-table-settings';
import { IssueActions } from 'app/core/store/issue';
import { KanbanBoardActions } from 'app/core/store/kanban';
import { LayoutActions } from 'app/core/store/layout';
import { MinuteActions } from 'app/core/store/minute';
import { NotificationActions } from 'app/core/store/notification';
import { PortfolioActions } from 'app/core/store/portfolio';
import { ProgramMemberActions } from 'app/core/store/program-member';
import { ProgramRoleActions } from 'app/core/store/program-role';
import { ProgramStatusActions } from 'app/core/store/program-status-report';
import { ProjectFieldActions } from 'app/core/store/project-field';
import { ProjectMemberActions } from 'app/core/store/project-member';
import { ProjectPhaseActions } from 'app/core/store/project-phase';
import { ListContainerActions } from 'app/core/store/project-phase-container';
import { ProjectRequestActions } from 'app/core/store/project-request';
import { ProjectRoleActions } from 'app/core/store/project-role';
import { ProjectStatusActions } from 'app/core/store/project-status-report';
import { ProjectTemplateActions } from 'app/core/store/project-template';
import { ReminderActions } from 'app/core/store/reminder';
import { SiteMemberActions } from 'app/core/store/resource';
import { RiskActions } from 'app/core/store/risks';
import { ScheduleTaskActions } from 'app/core/store/schedule-template';
import { SiteRoleActions } from 'app/core/store/site-role';
import { SiteSettingsActions } from 'app/core/store/site-settings';
import { SprintActions } from 'app/core/store/sprint';
import { TimesheetActions } from 'app/core/store/timesheet';
import { UserLogActions } from 'app/core/store/user-log';
import { VendorActions } from 'app/core/store/vendor';
import { RestfulCommandBuilder, RestfulCommandBuilderWithPayload } from 'app/utils/network';
import { AccountActions } from '../../store/account';
import { ProgramActions } from '../../store/program';
import { ProjectActions } from '../../store/project';
import { SiteActions } from '../../store/site';
import { SubscriptionActions } from '../../store/subscription';
import { SubscriptionPlanActions } from '../../store/subscription-plan';
import { AccountCommandBuilders } from './features/account';
import { ActionCommandBuilders } from './features/action';
import { AdminCommandBuilders } from './features/admin';
import { AllocationCommandBuilders } from './features/allocation/index';
import { ApprovalCommandBuilders } from './features/approvals';
import { BacklogTaskCommandBuilders } from './features/backlog-task';
import { BudgetTableCommandBuilders } from './features/budget-table';
import { ChangeRequestCommandBuilders } from './features/change-request';
import { CommentCommandBuilders } from './features/comment';
import { CommunicationCommandBuilders } from './features/communication';
import { DecisionCommandBuilders } from './features/decision';
import { DocumentCommandBuilders } from './features/document';
import { ExecutiveDashboardCommandBuilders } from './features/executive-dashboard';
import { FolderCommandBuilders } from './features/folder';
import { GlTableSettingsCommandBuilders } from './features/gl-table-settings';
import { IssueCommandBuilders } from './features/issue';
import { KanbanBoardCommandBuilders } from './features/kanban-board';
import { ListContainerCommandBuilders } from './features/list-container';
import { MinuteCommandBuilders } from './features/minute';
import { NotificationCommandBuilders } from './features/notification';
import { PortfolioCommandBuilders } from './features/portfolio';
import { ProgramCommandBuilders } from './features/program';
import { ProgramMemberCommandBuilders } from './features/program-member';
import { ProgramRoleCommandBuilders } from './features/program-role';
import { ProgramStatusReportCommandBuilders } from './features/program-status-report';
import { ProjectCommandBuilders } from './features/project';
import { CalendarCommandBuilders } from './features/project-calendar';
import { ProjectFieldCommandBuilders } from './features/project-field';
import { ProjectMemberCommandBuilders } from './features/project-member';
import { ProjectPhaseCommandBuilders } from './features/project-phase';
import { ProjectRequestCommandBuilders } from './features/project-request';
import { ProjectRoleCommandBuilders } from './features/project-role';
import { ProjectStatusReportCommandBuilders } from './features/project-status-report';
import { ProjectTemplateCommandBuilders } from './features/project-template';
import { ReminderCommandBuilders } from './features/reminder';
import { RiskCommandBuilders } from './features/risk';
import { ScheduleTaskCommandBuilders } from './features/schedule-task';
import { SiteCommandBuilders } from './features/site';
import { SiteMemberCommandBuilders } from './features/site-member';
import { SiteRoleCommandBuilders } from './features/site-role';
import { SprintCommandBuilders } from './features/sprint';
import { SubscriptionCommandBuilders } from './features/subscription';
import { TimesheetCommandBuilders } from './features/timesheet';
import { UserLogCommandBuilders } from './features/user-log';
import { VendorCommandBuilders } from './features/vendor';

interface ActionWithPayload extends Action {
    payload?: any;
}

export class CommandBuilderFactory {
    private commandBuilders = new Map<string, RestfulCommandBuilder>();

    constructor() {
        this.registerCommandBuilders();
    }

    getCommandBuilderWithPayload(action: ActionWithPayload): RestfulCommandBuilderWithPayload {
        const type = action.type;
        if (this.commandBuilders.has(type)) {
            return this.commandBuilders.get(type).bind(null, action.payload);
        }
        return null;
    }

    private add(action: string, command: RestfulCommandBuilder) {
        this.commandBuilders.set(action, command);
    }

    private registerCommandBuilders() {
        // Register EXECUTIVE DASHBOARD command builders
        this.add(
            ExecutiveDashboardActions.loadExecutiveDashboardBySiteId.type,
            ExecutiveDashboardCommandBuilders.loadExecutiveDashboardBySiteId
        );
        this.add(
            ExecutiveDashboardActions.deleteExecutiveDashboardMinuteTask.type,
            ExecutiveDashboardCommandBuilders.deleteExecutiveDashboardMinuteTask
        );

        // Register SITES command builders
        this.add(SiteActions.loadSites.type, SiteCommandBuilders.loadSites);
        this.add(SiteActions.loadInitialSite.type, SiteCommandBuilders.loadInitialSite);
        this.add(SiteActions.loadRolesBySite.type, SiteCommandBuilders.loadRolesBySite);
        this.add(SiteActions.loadSiteMetrics.type, SiteCommandBuilders.loadSiteMetrics);
        this.add(SiteActions.getSiteById.type, SiteCommandBuilders.getSiteById);
        this.add(SiteActions.addSite.type, SiteCommandBuilders.addSite);
        this.add(SiteActions.patchSite.type, SiteCommandBuilders.patchSite);
        this.add(SiteActions.deleteSite.type, SiteCommandBuilders.deleteSite);
        this.add(ProjectActions.getProjectsBySite.type, SiteCommandBuilders.getProjectsBySite);
        this.add(SiteActions.getSubscriptionPlans.type, SiteCommandBuilders.getSubscriptionPlans);
        this.add(
            SiteActions.getSubscriptionPlanById.type,
            SiteCommandBuilders.getSubscriptionPlanById
        );
        this.add(
            SiteActions.requestChangeSiteOwner.type,
            SiteCommandBuilders.requestChangeSiteOwner
        );
        this.add(
            SiteActions.cancelRequestChangeSiteOwner.type,
            SiteCommandBuilders.cancelRequestChangeSiteOwner
        );
        this.add(
            SiteActions.acceptRequestChangeSiteOwner.type,
            SiteCommandBuilders.acceptRequestChangeSiteOwner
        );
        this.add(SiteSettingsActions.loadSiteSettings.type, SiteCommandBuilders.loadSiteSettings);
        this.add(
            SiteSettingsActions.loadSiteSettingsCompletixTemplates.type,
            SiteCommandBuilders.loadSiteSettingsCompletixTemplates
        );
        this.add(SiteSettingsActions.patchSiteSettings.type, SiteCommandBuilders.patchSiteSettings);
        this.add(
            SiteSettingsActions.addSiteSettings.type,
            SiteCommandBuilders.addSiteSettingsTemplate
        );
        this.add(
            SiteSettingsActions.deleteSiteSettings.type,
            SiteCommandBuilders.deleteSiteSettingsTemplate
        );

        // Register PROGRAMS command builders
        this.add(ProgramActions.loadPrograms.type, ProgramCommandBuilders.loadPrograms);
        this.add(ProgramActions.getProgramById.type, ProgramCommandBuilders.getProgramById);
        this.add(
            ProgramActions.getProgramPermissions.type,
            ProgramCommandBuilders.getProgramPermissions
        );
        this.add(ProgramActions.getInitialProgram.type, ProgramCommandBuilders.getInitialProgram);
        this.add(ProgramActions.addProgram.type, ProgramCommandBuilders.addProgram);
        this.add(ProgramActions.patchProgram.type, ProgramCommandBuilders.patchProgram);
        this.add(ProgramActions.deleteProgram.type, ProgramCommandBuilders.deleteProgram);
        this.add(
            ProjectActions.getProjectsByProgram.type,
            ProgramCommandBuilders.getProjectsByProgram
        );

        // Register PROJECTS command builders
        this.add(ProjectActions.getProject.type, ProjectCommandBuilders.getProject);
        this.add(ProjectActions.getInitialProject.type, ProjectCommandBuilders.getInitialProject);
        this.add(
            ProjectActions.getProjectPermissions.type,
            ProjectCommandBuilders.getProjectPermissions
        );
        this.add(ProjectActions.addProject.type, ProjectCommandBuilders.addProject);
        this.add(ProjectActions.updateProject.type, ProjectCommandBuilders.updateProject);
        this.add(ProjectActions.patchProject.type, ProjectCommandBuilders.patchProject);
        this.add(ProjectActions.archiveProject.type, ProjectCommandBuilders.archiveProject);
        this.add(ProjectActions.unarchiveProject.type, ProjectCommandBuilders.unarchiveProject);
        this.add(ProjectActions.deleteProject.type, ProjectCommandBuilders.deleteProject);
        this.add(ProjectActions.getProjectSettings.type, ProjectCommandBuilders.getProjectSettings);
        this.add(
            ProjectActions.patchProjectSettings.type,
            ProjectCommandBuilders.patchProjectSettings
        );
        this.add(
            ProjectActions.getRaciTableByProject.type,
            ProjectCommandBuilders.getProjectRaciTable
        );
        this.add(
            ProjectActions.updateRaciTable.type,
            ProjectCommandBuilders.updateProjectRaciTable
        );
        this.add(
            ProjectActions.importMicrosoftProject.type,
            ProjectCommandBuilders.importMicrosoftProject
        );

        // Register PORTFOLIOS command builders
        this.add(
            PortfolioActions.loadPortfolios.type,
            PortfolioCommandBuilders.loadPortfoliosBySiteIdCommand
        );
        this.add(PortfolioActions.addPortfolio.type, PortfolioCommandBuilders.addPortfolioCommand);
        this.add(
            PortfolioActions.patchPortfolio.type,
            PortfolioCommandBuilders.patchPortfolioCommand
        );
        this.add(
            PortfolioActions.addProjectToPortfolio.type,
            PortfolioCommandBuilders.addProjectToPortfolioCommand
        );
        this.add(
            PortfolioActions.removeProjectFromPortfolio.type,
            PortfolioCommandBuilders.removeProjectFromPortfolioCommand
        );
        this.add(
            PortfolioActions.deletePortfolio.type,
            PortfolioCommandBuilders.deletePortfolioCommand
        );

        // Register APPROVALS command builders
        this.add(
            ApprovalRequestActions.loadApprovalRequests.type,
            ApprovalCommandBuilders.loadApprovalsBySiteIdCommand
        );

        // Register CALENDARS command builders
        this.add(
            CalendarActions.loadCompletixCalendarTemplates.type,
            CalendarCommandBuilders.loadCompletixCalendarTemplates
        );
        this.add(
            CalendarActions.loadCompletixCalendarTemplatesBySiteId.type,
            CalendarCommandBuilders.loadCompletixCalendarTemplatesBySiteId
        );
        this.add(
            CalendarActions.loadCalendarsBySiteId.type,
            CalendarCommandBuilders.loadCalendarsBySiteId
        );
        this.add(
            CalendarActions.loadCalendarByProjectId.type,
            CalendarCommandBuilders.loadCalendarByProjectId
        );
        this.add(CalendarActions.addCalendar.type, CalendarCommandBuilders.addCalendar);
        this.add(
            CalendarActions.importCompletixCalendarTemplate.type,
            CalendarCommandBuilders.importCalendar
        );
        this.add(CalendarActions.patchCalendar.type, CalendarCommandBuilders.patchCalendar);
        this.add(CalendarActions.cloneCalendar.type, CalendarCommandBuilders.cloneCalendar);
        this.add(
            CalendarActions.changeCalendarTemplate.type,
            CalendarCommandBuilders.changeProjectCalendarTemplate
        );
        this.add(CalendarActions.deleteCalendar.type, CalendarCommandBuilders.deleteCalendar);

        // Register CHANGE REQUESTS command builders
        this.add(
            ChangeRequestActions.loadChangeRequestsByProjectId.type,
            ChangeRequestCommandBuilders.loadChangeRequestsByProjectIdCommand
        );
        this.add(
            ChangeRequestActions.loadChangeRequestsBySiteId.type,
            ChangeRequestCommandBuilders.loadChangeRequestsBySiteIdCommand
        );
        this.add(
            ChangeRequestActions.loadChangeRequestApprovers.type,
            ChangeRequestCommandBuilders.loadChangeRequestApproversByProjectIdCommand
        );
        this.add(
            ChangeRequestActions.getChangeRequestById.type,
            ChangeRequestCommandBuilders.getChangeRequestByIdCommand
        );
        this.add(
            ChangeRequestActions.addChangeRequest.type,
            ChangeRequestCommandBuilders.addChangeRequestCommand
        );
        this.add(
            ChangeRequestActions.patchChangeRequest.type,
            ChangeRequestCommandBuilders.patchChangeRequestCommand
        );
        this.add(
            ChangeRequestActions.deleteChangeRequest.type,
            ChangeRequestCommandBuilders.deleteChangeRequestCommand
        );
        this.add(
            ChangeRequestActions.addChangeRequestApproval.type,
            ChangeRequestCommandBuilders.addChangeRequestApprovalCommand
        );
        this.add(
            ChangeRequestActions.deleteChangeRequestApproval.type,
            ChangeRequestCommandBuilders.deleteChangeRequestApprovalCommand
        );
        this.add(
            ChangeRequestActions.manageChangeRequestApprovalSubmission.type,
            ChangeRequestCommandBuilders.manageChangeRequestApprovalSubmissionCommand
        );
        this.add(
            ChangeRequestActions.manageChangeRequestApproval.type,
            ChangeRequestCommandBuilders.manageChangeRequestApprovalCommand
        );
        this.add(
            ChangeRequestActions.patchChangeRequestApproval.type,
            ChangeRequestCommandBuilders.patchChangeRequestApprovalCommand
        );
        this.add(
            ChangeRequestActions.reOpenChangeRequest.type,
            ChangeRequestCommandBuilders.reOpenChangeRequestCommand
        );

        // Register VENDORS command builders
        this.add(
            VendorActions.loadVendorsByProjectId.type,
            VendorCommandBuilders.loadVendorsByProjectIdCommand
        );
        this.add(
            VendorActions.loadVendorsBySiteId.type,
            VendorCommandBuilders.loadVendorsBySiteIdCommand
        );
        this.add(VendorActions.addVendor.type, VendorCommandBuilders.addVendorCommand);
        this.add(VendorActions.patchVendor.type, VendorCommandBuilders.patchVendorCommand);
        this.add(VendorActions.deleteVendor.type, VendorCommandBuilders.deleteVendorCommand);

        // Register MINUTES command builders
        this.add(
            MinuteActions.loadMinutesByProjectId.type,
            MinuteCommandBuilders.loadMinutesByProjectIdCommand
        );
        this.add(
            MinuteActions.loadMinutesBySiteId.type,
            MinuteCommandBuilders.loadMinutesBySiteIdCommand
        );
        this.add(MinuteActions.addMinute.type, MinuteCommandBuilders.addMinuteCommand);
        this.add(MinuteActions.cloneMinute.type, MinuteCommandBuilders.cloneMinuteCommand);
        this.add(MinuteActions.patchMinute.type, MinuteCommandBuilders.patchMinuteCommand);
        this.add(MinuteActions.deleteMinute.type, MinuteCommandBuilders.deleteMinuteCommand);
        this.add(MinuteActions.sendMinutePreview.type, MinuteCommandBuilders.sendMinutePreview);

        // Register FOLDERS command builders
        this.add(
            FolderActions.loadFoldersByTag.type,
            FolderCommandBuilders.loadFoldersByTagCommand
        );
        this.add(FolderActions.addFolder.type, FolderCommandBuilders.addFolderCommand);
        this.add(FolderActions.patchFolder.type, FolderCommandBuilders.patchFolderCommand);
        this.add(FolderActions.deleteFolder.type, FolderCommandBuilders.deleteFolderCommand);

        // Register PROJECT PHASES command builders
        this.add(
            ProjectPhaseActions.loadProjectPhasesByContainerId.type,
            ProjectPhaseCommandBuilders.loadProjectPhasesByContainerIdCommand
        );
        this.add(
            ProjectPhaseActions.loadProjectPhasesBySiteId.type,
            ProjectPhaseCommandBuilders.loadProjectPhasesBySiteIdCommand
        );
        this.add(
            ProjectPhaseActions.getProjectPhaseById.type,
            ProjectPhaseCommandBuilders.getProjectPhaseByIdCommand
        );
        this.add(
            ProjectPhaseActions.addProjectPhase.type,
            ProjectPhaseCommandBuilders.addProjectPhaseCommand
        );
        this.add(
            ProjectPhaseActions.patchProjectPhase.type,
            ProjectPhaseCommandBuilders.patchProjectPhaseCommand
        );
        this.add(
            ProjectPhaseActions.advanceProjectPhase.type,
            ProjectPhaseCommandBuilders.advanceProjectPhaseCommand
        );
        this.add(
            ProjectPhaseActions.submitProjectPhase.type,
            ProjectPhaseCommandBuilders.submitProjectPhaseCommand
        );
        this.add(
            ProjectPhaseActions.unsubmitProjectPhase.type,
            ProjectPhaseCommandBuilders.unsubmitProjectPhaseCommand
        );
        this.add(
            ProjectPhaseActions.deleteProjectPhase.type,
            ProjectPhaseCommandBuilders.deleteProjectPhaseCommand
        );
        this.add(
            ProjectPhaseActions.changeGatingTemplate.type,
            ProjectPhaseCommandBuilders.changeGatingTemplateCommand
        );
        this.add(
            ProjectPhaseActions.manageProjectPhaseApproval.type,
            ProjectPhaseCommandBuilders.manageProjectPhaseApprovalCommand
        );
        this.add(
            ProjectPhaseActions.addProjectPhaseDeliverable.type,
            ProjectPhaseCommandBuilders.addProjectPhaseDeliverableCommand
        );
        this.add(
            ProjectPhaseActions.patchProjectPhaseDeliverable.type,
            ProjectPhaseCommandBuilders.patchProjectPhaseDeliverableCommand
        );
        this.add(
            ProjectPhaseActions.deleteProjectPhaseDeliverable.type,
            ProjectPhaseCommandBuilders.deleteProjectPhaseDeliverableCommand
        );

        // Register LIST CONTAINERS command builders
        this.add(
            ListContainerActions.loadSystemListContainers.type,
            ListContainerCommandBuilders.loadSystemListContainersCommand
        );
        this.add(
            ListContainerActions.loadSystemListContainersBySiteId.type,
            ListContainerCommandBuilders.loadSystemListContainersBySiteIdCommand
        );
        this.add(
            ListContainerActions.loadListContainersBySiteId.type,
            ListContainerCommandBuilders.loadListContainersBySiteIdCommand
        );
        this.add(
            ListContainerActions.loadListContainersByProjectId.type,
            ListContainerCommandBuilders.loadListContainerByProjectIdCommand
        );
        this.add(
            ListContainerActions.importSystemListContainer.type,
            ListContainerCommandBuilders.importListContainerCommand
        );
        this.add(
            ListContainerActions.addListContainer.type,
            ListContainerCommandBuilders.addListContainerCommand
        );
        this.add(
            ListContainerActions.patchListContainer.type,
            ListContainerCommandBuilders.patchListContainerCommand
        );
        this.add(
            ListContainerActions.deleteListContainer.type,
            ListContainerCommandBuilders.deleteListContainerCommand
        );
        this.add(
            ListContainerActions.changeListContainerTemplate.type,
            ListContainerCommandBuilders.changeListContainerTemplateCommand
        );

        // Register RISKS command builders
        this.add(RiskActions.getRisk.type, RiskCommandBuilders.getRiskCommand);
        this.add(
            RiskActions.loadRisksByProjectId.type,
            RiskCommandBuilders.loadRisksByProjectIdCommand
        );
        this.add(RiskActions.loadRisksBySiteId.type, RiskCommandBuilders.loadRisksBySiteIdCommand);
        this.add(RiskActions.addRisk.type, RiskCommandBuilders.addRiskCommand);
        this.add(RiskActions.patchRisk.type, RiskCommandBuilders.patchRiskCommand);
        this.add(RiskActions.deleteRisk.type, RiskCommandBuilders.deleteRiskCommand);

        // Register ACTIONS command builders
        this.add(ActionActions.getAction.type, ActionCommandBuilders.getActionCommand);
        this.add(
            ActionActions.loadActionsByProjectId.type,
            ActionCommandBuilders.loadActionsByProjectIdCommand
        );
        this.add(
            ActionActions.loadActionsBySiteId.type,
            ActionCommandBuilders.loadActionsBySiteIdCommand
        );
        this.add(ActionActions.addAction.type, ActionCommandBuilders.addActionCommand);
        this.add(ActionActions.patchAction.type, ActionCommandBuilders.patchActionCommand);
        this.add(ActionActions.deleteAction.type, ActionCommandBuilders.deleteActionCommand);

        // Register ISSUES command builders
        this.add(IssueActions.getIssue.type, IssueCommandBuilders.getIssueCommand);
        this.add(
            IssueActions.loadIssuesByProjectId.type,
            IssueCommandBuilders.loadIssuesByProjectIdCommand
        );
        this.add(
            IssueActions.loadIssuesBySiteId.type,
            IssueCommandBuilders.loadIssuesBySiteIdCommand
        );
        this.add(IssueActions.addIssue.type, IssueCommandBuilders.addIssueCommand);
        this.add(IssueActions.patchIssue.type, IssueCommandBuilders.patchIssueCommand);
        this.add(IssueActions.deleteIssue.type, IssueCommandBuilders.deleteIssueCommand);

        // Register DECISIONS command builders
        this.add(
            DecisionActions.loadDecisionsByProjectId.type,
            DecisionCommandBuilders.loadDecisionsByProjectIdCommand
        );
        this.add(
            DecisionActions.loadDecisionsBySiteId.type,
            DecisionCommandBuilders.loadDecisionsBySiteIdCommand
        );
        this.add(
            DecisionActions.getDecisionById.type,
            DecisionCommandBuilders.getAssignedDecisionByIdCommand
        );
        this.add(DecisionActions.addDecision.type, DecisionCommandBuilders.addDecisionCommand);
        this.add(DecisionActions.patchDecision.type, DecisionCommandBuilders.patchDecisionCommand);
        this.add(
            DecisionActions.deleteDecision.type,
            DecisionCommandBuilders.deleteDecisionCommand
        );
        this.add(
            DecisionActions.submitDecision.type,
            DecisionCommandBuilders.submitDecisionCommand
        );
        this.add(
            DecisionActions.manageDecisionApproval.type,
            DecisionCommandBuilders.manageDecisionApprovalCommand
        );

        // Register KANBAN BOARDS command builders
        this.add(
            KanbanBoardActions.loadKanbanBoardsByProjectId.type,
            KanbanBoardCommandBuilders.loadKanbanBoardsByProjectIdCommand
        );
        this.add(
            KanbanBoardActions.loadKanbanBoardsBySiteId.type,
            KanbanBoardCommandBuilders.loadKanbanBoardsBySiteIdCommand
        );
        this.add(
            KanbanBoardActions.patchKanbanBoard.type,
            KanbanBoardCommandBuilders.patchKanbanBoardCommand
        );

        // Register COMMUNICATION command builders
        this.add(
            CommunicationActions.loadCommunicationByProjectId.type,
            CommunicationCommandBuilders.loadCommunicationsByProjectIdCommand
        );
        this.add(
            CommunicationActions.loadCommunicationBySiteId.type,
            CommunicationCommandBuilders.loadCommunicationsBySiteIdCommand
        );
        this.add(
            CommunicationActions.loadCompletixCommunicationTemplatesBySiteId.type,
            CommunicationCommandBuilders.loadCompletixCommunicationTemplatesBySiteIdCommand
        );
        this.add(
            CommunicationActions.loadCompletixCommunicationTemplates.type,
            CommunicationCommandBuilders.loadCompletixCommunicationTemplatesCommand
        );
        this.add(
            CommunicationActions.addCommunication.type,
            CommunicationCommandBuilders.addCommunicationCommand
        );
        this.add(
            CommunicationActions.importCompletixCommunicationTemplate.type,
            CommunicationCommandBuilders.importCompletixCommunicationTemplateCommand
        );
        this.add(
            CommunicationActions.patchCommunication.type,
            CommunicationCommandBuilders.patchCommunicationCommand
        );
        this.add(
            CommunicationActions.deleteCommunication.type,
            CommunicationCommandBuilders.deleteCommunicationCommand
        );
        this.add(
            CommunicationActions.changeProjectCommunicationTemplate.type,
            CommunicationCommandBuilders.changeProjectCommunicationTemplateCommand
        );

        // Register PROJECT STATUS REPORTS command builders
        this.add(
            ProjectStatusActions.loadProjectStatuses.type,
            ProjectStatusReportCommandBuilders.loadProjectStatusReportsByProjectIdCommand
        );
        this.add(
            ProjectStatusActions.loadProjectStatusesBySiteId.type,
            ProjectStatusReportCommandBuilders.loadProjectStatusReportsBySiteIdCommand
        );
        this.add(
            ProjectStatusActions.addProjectStatus.type,
            ProjectStatusReportCommandBuilders.addProjectStatusReportCommand
        );
        this.add(
            ProjectStatusActions.patchProjectStatus.type,
            ProjectStatusReportCommandBuilders.patchProjectStatusReportCommand
        );
        this.add(
            ProjectStatusActions.deleteProjectStatus.type,
            ProjectStatusReportCommandBuilders.deleteProjectStatusReportCommand
        );

        // Register PROGRAM STATUS REPORTS command builders
        this.add(
            ProgramStatusActions.loadProgramStatuses.type,
            ProgramStatusReportCommandBuilders.loadProgramStatusReportsByProgramIdCommand
        );
        this.add(
            ProgramStatusActions.loadProgramStatusesBySiteId.type,
            ProgramStatusReportCommandBuilders.loadProgramStatusReportsBySiteIdCommand
        );
        this.add(
            ProgramStatusActions.addProgramStatus.type,
            ProgramStatusReportCommandBuilders.addProgramStatusReportCommand
        );
        this.add(
            ProgramStatusActions.patchProgramStatus.type,
            ProgramStatusReportCommandBuilders.patchProgramStatusReportCommand
        );
        this.add(
            ProgramStatusActions.deleteProgramStatus.type,
            ProgramStatusReportCommandBuilders.deleteProgramStatusReportCommand
        );

        // Register COMMENTS command builders
        this.add(
            CommentActions.loadCommentsBySiteId.type,
            CommentCommandBuilders.loadCommentsBySiteIdCommand
        );
        this.add(CommentActions.addComment.type, CommentCommandBuilders.addCommentCommand);
        this.add(CommentActions.patchComment.type, CommentCommandBuilders.patchCommentCommand);
        this.add(CommentActions.deleteComment.type, CommentCommandBuilders.deleteCommentCommand);

        // Register REMINDERS command builders
        this.add(
            ReminderActions.loadReminders.type,
            ReminderCommandBuilders.loadRemindersBySiteIdCommand
        );
        this.add(ReminderActions.addReminder.type, ReminderCommandBuilders.addReminderCommand);
        this.add(ReminderActions.patchReminder.type, ReminderCommandBuilders.patchReminderCommand);
        this.add(
            ReminderActions.deleteReminder.type,
            ReminderCommandBuilders.deleteReminderCommand
        );

        // Register SCHEDULE TASKS command builders
        this.add(
            ScheduleTaskActions.loadScheduleTasksByContainerId.type,
            ScheduleTaskCommandBuilders.loadScheduleTasksByContainerIdCommand
        );
        this.add(
            ScheduleTaskActions.loadScheduleTasksBySiteId.type,
            ScheduleTaskCommandBuilders.loadScheduleTasksBySiteIdCommand
        );
        this.add(
            ScheduleTaskActions.addScheduleTask.type,
            ScheduleTaskCommandBuilders.addScheduleTaskCommand
        );
        this.add(
            ScheduleTaskActions.patchScheduleTasks.type,
            ScheduleTaskCommandBuilders.patchScheduleTaskCommand
        );
        this.add(
            ScheduleTaskActions.deleteScheduleTask.type,
            ScheduleTaskCommandBuilders.deleteScheduleTaskCommand
        );

        // Register BUDGET TABLES command builders
        this.add(
            BudgetTableActions.loadBudgetTableByProjectId.type,
            BudgetTableCommandBuilders.loadProjectBudgetTableByProjectIdCommand
        );
        this.add(
            BudgetTableActions.loadCompletixBudgetTableTemplatesBySiteId.type,
            BudgetTableCommandBuilders.loadCompletixProjectBudgetTableTempalatesBySiteIdCommand
        );
        this.add(
            BudgetTableActions.loadCompletixBudgetTableTemplates.type,
            BudgetTableCommandBuilders.loadCompletixProjectBudgetTableTempalatesCommand
        );
        this.add(
            BudgetTableActions.loadBudgetTablesBySiteId.type,
            BudgetTableCommandBuilders.loadProjectBudgetTablesBySiteIdCommand
        );
        this.add(
            BudgetTableActions.importCompletixBudgetTable.type,
            BudgetTableCommandBuilders.importProjectBudgetTableCommand
        );
        this.add(
            BudgetTableActions.addBudgetTable.type,
            BudgetTableCommandBuilders.addProjectBudgetTableCommand
        );
        this.add(
            BudgetTableActions.patchBudgetTable.type,
            BudgetTableCommandBuilders.patchProjectBudgetTableCommand
        );
        this.add(
            BudgetTableActions.deleteBudgetTable.type,
            BudgetTableCommandBuilders.deleteProjectBudgetTableCommand
        );
        this.add(
            BudgetTableActions.changeBudgetTableTemplate.type,
            BudgetTableCommandBuilders.changeProjectProjectBudgetTableTemplateCommand
        );
        this.add(
            BudgetTableActions.importBudgetTableDocument.type,
            BudgetTableCommandBuilders.importBudgetTableDocument
        );

        // Register PROJECT TEMPLATES command builders
        this.add(
            ProjectTemplateActions.loadProjectTemplates.type,
            ProjectTemplateCommandBuilders.loadProjectTemplatesCommand
        );
        this.add(
            ProjectTemplateActions.loadCompletixProjectTemplates.type,
            ProjectTemplateCommandBuilders.loadCompletixProjectTemplatesCommand
        );
        this.add(
            ProjectTemplateActions.loadCompletixProjectTemplatesBySiteId.type,
            ProjectTemplateCommandBuilders.loadCompletixProjectTemplatesBySiteIdCommand
        );
        this.add(
            ProjectTemplateActions.addProjectTemplate.type,
            ProjectTemplateCommandBuilders.addProjectTemplateCommand
        );
        this.add(
            ProjectTemplateActions.importCompletixProjectTemplate.type,
            ProjectTemplateCommandBuilders.importProjectTemplateCommand
        );
        this.add(
            ProjectTemplateActions.patchProjectTemplate.type,
            ProjectTemplateCommandBuilders.patchProjectTemplateCommand
        );
        this.add(
            ProjectTemplateActions.deleteProjectTemplate.type,
            ProjectTemplateCommandBuilders.deleteProjectTemplateCommand
        );

        // Register PROJECT REQUESTS command builders
        this.add(
            ProjectRequestActions.loadProjectRequests.type,
            ProjectRequestCommandBuilders.loadProjectRequestsCommand
        );
        this.add(
            ProjectRequestActions.addProjectRequest.type,
            ProjectRequestCommandBuilders.addProjectRequestCommand
        );
        this.add(
            ProjectRequestActions.patchProjectRequest.type,
            ProjectRequestCommandBuilders.patchProjectRequestCommand
        );
        this.add(
            ProjectRequestActions.deleteProjectRequest.type,
            ProjectRequestCommandBuilders.deleteProjectRequestCommand
        );

        // Register PROJECT FIELDS command builders
        this.add(
            ProjectFieldActions.loadProjectFieldsBySiteId.type,
            ProjectFieldCommandBuilders.loadProjectFieldsBySiteId
        );
        this.add(
            ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplate.type,
            ProjectFieldCommandBuilders.loadProjectFieldsByCompletixSettingsTemplate
        );
        this.add(
            ProjectFieldActions.patchProjectField.type,
            ProjectFieldCommandBuilders.patchProjectField
        );

        // Register SITE MEMBERS command builders
        this.add(
            SiteMemberActions.loadMembersBySiteId.type,
            SiteMemberCommandBuilders.loadMembersBySiteId
        );
        this.add(
            SiteMemberActions.loadMySiteMember.type,
            SiteMemberCommandBuilders.loadMySiteMember
        );
        this.add(
            SiteMemberActions.inviteSiteMembersToSite.type,
            SiteMemberCommandBuilders.inviteSiteMember
        );
        this.add(SiteMemberActions.patchSiteMember.type, SiteMemberCommandBuilders.patchSiteMember);
        this.add(
            SiteMemberActions.deleteSiteMember.type,
            SiteMemberCommandBuilders.deleteSiteMember
        );
        this.add(SiteMemberActions.resendInvite.type, SiteMemberCommandBuilders.resendInvite);

        // Register TIMESHEETS command builders
        this.add(
            TimesheetActions.loadTimesheetBySiteMemberId.type,
            TimesheetCommandBuilders.loadTimesheetsBySiteMemberIdCommand
        );
        this.add(
            TimesheetActions.loadTimesheetsBySiteId.type,
            TimesheetCommandBuilders.loadTimesheetsBySiteId
        );
        this.add(
            TimesheetActions.patchTimesheet.type,
            TimesheetCommandBuilders.patchTimesheetCommand
        );
        this.add(
            TimesheetActions.manageActivityApproval.type,
            TimesheetCommandBuilders.manageActivityApprovalCommand
        );
        this.add(
            TimesheetActions.reOpenTimesheetWeek.type,
            TimesheetCommandBuilders.reOpenTimesheetWeekCommand
        );
        this.add(
            TimesheetActions.submitTimesheetWeek.type,
            TimesheetCommandBuilders.submitTimesheetWeekCommand
        );
        this.add(
            TimesheetActions.reSubmitTimesheetWeekActivity.type,
            TimesheetCommandBuilders.reSubmitTimesheetWeekActivityCommand
        );

        // Register PROJECT MEMBERS command builders
        this.add(
            ProjectMemberActions.loadProjectMembersBySiteId.type,
            ProjectMemberCommandBuilders.loadMembersBySiteId
        );
        this.add(
            ProjectMemberActions.loadProjectMembersByProjectId.type,
            ProjectMemberCommandBuilders.loadMembersByProjectId
        );
        this.add(
            ProjectMemberActions.inviteProjectMember.type,
            ProjectMemberCommandBuilders.inviteProjectMember
        );
        this.add(
            ProjectMemberActions.patchProjectMember.type,
            ProjectMemberCommandBuilders.patchProjectMember
        );
        this.add(
            ProjectMemberActions.deleteProjectMember.type,
            ProjectMemberCommandBuilders.deleteProjectMember
        );
        this.add(
            ProjectMemberActions.setPrimaryProjectManager.type,
            ProjectMemberCommandBuilders.setPrimaryProjectManager
        );
        this.add(ProjectMemberActions.resendInvite.type, ProjectMemberCommandBuilders.resendInvite);
        this.add(
            ProjectMemberActions.reOpenProjectMember.type,
            ProjectMemberCommandBuilders.reOpenProjectMember
        );

        // Register PROGRAM MEMBERS command builders
        this.add(
            ProgramMemberActions.loadProgramMembersByProgramId.type,
            ProgramMemberCommandBuilders.loadMembersByProgramId
        );
        this.add(
            ProgramMemberActions.loadProgramMembersBySiteId.type,
            ProgramMemberCommandBuilders.loadMembersBySiteId
        );
        this.add(
            ProgramMemberActions.inviteProgramMember.type,
            ProgramMemberCommandBuilders.inviteProgramMember
        );
        this.add(
            ProgramMemberActions.patchProgramMember.type,
            ProgramMemberCommandBuilders.patchProgramMember
        );
        this.add(
            ProgramMemberActions.deleteProgramMember.type,
            ProgramMemberCommandBuilders.deleteProgramMember
        );

        // Register SITE ROLES command builders
        this.add(SiteRoleActions.loadRolesBySite.type, SiteRoleCommandBuilders.loadSiteRolesBySite);
        this.add(
            SiteRoleActions.loadSiteRoleTemplates.type,
            SiteRoleCommandBuilders.loadSiteRoleTemplates
        );
        this.add(SiteRoleActions.addSiteRole.type, SiteRoleCommandBuilders.addSiteRole);
        this.add(SiteRoleActions.patchSiteRole.type, SiteRoleCommandBuilders.patchSiteRole);
        this.add(SiteRoleActions.setSiteRoleTag.type, SiteRoleCommandBuilders.setSiteRoleTag);
        this.add(SiteRoleActions.deleteSiteRole.type, SiteRoleCommandBuilders.deleteSiteRole);

        // Register PROJECT ROLES command builders
        this.add(
            ProjectRoleActions.loadProjectRoleTemplates.type,
            ProjectRoleCommandBuilders.loadProjectRoleTemplates
        );
        this.add(
            ProjectRoleActions.loadProjectRolesBySite.type,
            ProjectRoleCommandBuilders.loadProjectRolesBySite
        );
        this.add(ProjectRoleActions.addProjectRole.type, ProjectRoleCommandBuilders.addProjectRole);
        this.add(
            ProjectRoleActions.patchProjectRole.type,
            ProjectRoleCommandBuilders.patchProjectRole
        );
        this.add(
            ProjectRoleActions.setProjectRoleTag.type,
            ProjectRoleCommandBuilders.setProjectRoleTag
        );
        this.add(
            ProjectRoleActions.deleteProjectRole.type,
            ProjectRoleCommandBuilders.deleteProjectRole
        );

        // Register PROGRAM ROLES command builders
        this.add(
            ProgramRoleActions.loadProgramRoleTemplates.type,
            ProgramRoleCommandBuilders.loadProgramRoles
        );
        this.add(
            ProgramRoleActions.loadProgramRolesBySite.type,
            ProgramRoleCommandBuilders.loadProgramRolesBySite
        );
        this.add(ProgramRoleActions.addProgramRole.type, ProgramRoleCommandBuilders.addProgramRole);
        this.add(
            ProgramRoleActions.patchProgramRole.type,
            ProgramRoleCommandBuilders.patchProgramRole
        );
        this.add(
            ProgramRoleActions.setProgramRoleTag.type,
            ProgramRoleCommandBuilders.setProgramRoleTag
        );
        this.add(
            ProgramRoleActions.deleteProgramRole.type,
            ProgramRoleCommandBuilders.deleteProgramRole
        );

        // Register ALLOCATIONS command builders
        this.add(
            AllocationActions.loadAllocationsBySite.type,
            AllocationCommandBuilders.loadAllocationsBySiteId
        );
        this.add(
            AllocationActions.loadAllocationsByProject.type,
            AllocationCommandBuilders.loadAllocationsByProjectId
        );
        this.add(AllocationActions.addAllocation.type, AllocationCommandBuilders.addAllocation);
        this.add(
            AllocationActions.addManualAllocation.type,
            AllocationCommandBuilders.addManualAllocation
        );
        this.add(AllocationActions.patchAllocation.type, AllocationCommandBuilders.patchAllocation);
        this.add(
            AllocationActions.deleteAllocation.type,
            AllocationCommandBuilders.deleteAllocation
        );

        // Register DOCUMENTS command builders
        this.add(DocumentActions.getDocumentById.type, DocumentCommandBuilders.getDocumentById);
        this.add(
            DocumentActions.loadCompletixDocumentTemplates.type,
            DocumentCommandBuilders.loadCompletixDocumentTemplates
        );
        this.add(
            DocumentActions.loadCompletixDocumentTemplatesBySiteId.type,
            DocumentCommandBuilders.loadCompletixDocumentTemplatesBySiteId
        );
        this.add(
            DocumentActions.loadDocumentsByProject.type,
            DocumentCommandBuilders.loadDocumentsByProject
        );
        this.add(
            DocumentActions.loadDocumentsByProgram.type,
            DocumentCommandBuilders.loadDocumentsByProgram
        );
        this.add(
            DocumentActions.loadDocumentsBySite.type,
            DocumentCommandBuilders.loadDocumentsBySite
        );
        this.add(
            DocumentActions.loadDocumentChangeRequestFormBySiteId.type,
            DocumentCommandBuilders.loadDocumentChangeRequestFormBySiteId
        );
        this.add(
            DocumentActions.loadCompletixDocumentChangeRequestFormBySiteId.type,
            DocumentCommandBuilders.loadCompletixDocumentChangeRequestFormBySiteId
        );
        this.add(
            DocumentActions.loadCompletixDocumentChangeRequestForm.type,
            DocumentCommandBuilders.loadCompletixDocumentChangeRequestForm
        );
        this.add(DocumentActions.addDocument.type, DocumentCommandBuilders.addDocument);
        this.add(DocumentActions.importDocument.type, DocumentCommandBuilders.importDocument);
        this.add(DocumentActions.deleteDocument.type, DocumentCommandBuilders.deleteDocument);
        this.add(DocumentActions.patchDocument.type, DocumentCommandBuilders.patchDocument);
        this.add(DocumentActions.copyDocuments.type, DocumentCommandBuilders.copyDocuments);
        this.add(DocumentActions.downloadDocument.type, DocumentCommandBuilders.downloadDocument);
        this.add(
            DocumentActions.getDocumentPreview.type,
            DocumentCommandBuilders.getDocumentPreview
        );
        this.add(
            DocumentActions.addDocumentVersion.type,
            DocumentCommandBuilders.addDocumentVersion
        );

        // Register BACKLOG TASKS command builders
        this.add(
            BacklogTaskActions.getBacklogTask.type,
            BacklogTaskCommandBuilders.getBacklogTaskCommand
        );
        this.add(
            BacklogTaskActions.loadBacklogTasksByContainerId.type,
            BacklogTaskCommandBuilders.loadBacklogTasksByContainerIdCommand
        );
        this.add(
            BacklogTaskActions.loadBacklogTasksBySiteId.type,
            BacklogTaskCommandBuilders.loadBacklogTasksBySiteIdCommand
        );
        this.add(
            BacklogTaskActions.addBacklogTask.type,
            BacklogTaskCommandBuilders.addBacklogTaskCommand
        );
        this.add(
            BacklogTaskActions.patchBacklogTask.type,
            BacklogTaskCommandBuilders.patchBacklogTaskCommand
        );
        this.add(
            BacklogTaskActions.deleteBacklogTask.type,
            BacklogTaskCommandBuilders.deleteBacklogTaskCommand
        );

        // Register SPRINTS command builders
        this.add(
            SprintActions.loadSprintsByProjectId.type,
            SprintCommandBuilders.loadSprintsByProjectId
        );
        this.add(SprintActions.addSprint.type, SprintCommandBuilders.addSprintCommand);
        this.add(SprintActions.patchSprint.type, SprintCommandBuilders.patchSprintCommand);
        this.add(SprintActions.deleteSprint.type, SprintCommandBuilders.deleteSprintCommand);

        // Register ACCOUNTS command builders
        this.add(AccountActions.registerAccount.type, AccountCommandBuilders.registerAccount);
        this.add(AccountActions.getMyAccount.type, AccountCommandBuilders.getMyAccount);
        this.add(AccountActions.getAccount.type, AccountCommandBuilders.getAccount);
        this.add(AccountActions.updateAccount.type, AccountCommandBuilders.updateAccount);
        this.add(AccountActions.deleteAccount.type, AccountCommandBuilders.deleteAccount);
        this.add(AccountActions.getAccountImage.type, AccountCommandBuilders.getAccountImage);
        this.add(AccountActions.updateAccountImage.type, AccountCommandBuilders.updateAccountImage);
        this.add(
            LayoutActions.loadLayoutSettings.type,
            AccountCommandBuilders.loadAccountLayoutSettings
        );
        this.add(
            LayoutActions.patchLayoutSettings.type,
            AccountCommandBuilders.patchAccountSetting
        );
        this.add(AccountActions.getSystemAdminData.type, AccountCommandBuilders.getSystemAdminData);
        this.add(
            AccountActions.patchSystemAdminData.type,
            AccountCommandBuilders.patchSystemAdminData
        );
        this.add(AccountActions.getUserAppMessage.type, AccountCommandBuilders.getUserAppMessage);

        // Register SUBSCRIPTIONS sommand builders
        this.add(
            SubscriptionActions.getActiveSubscription.type,
            SubscriptionCommandBuilders.getActibeSubscription
        );
        this.add(
            SubscriptionActions.changeSubscription.type,
            SubscriptionCommandBuilders.createSubscription
        );
        this.add(
            SubscriptionActions.patchBillingDetails.type,
            SubscriptionCommandBuilders.patchBillingDetails
        );
        this.add(
            SubscriptionActions.cancelSubscription.type,
            SubscriptionCommandBuilders.cancelSubscription
        );
        this.add(
            SubscriptionActions.getSubscriptionQuantities.type,
            SubscriptionCommandBuilders.getSubscriptionQuantities
        );
        this.add(SubscriptionActions.getInvoices.type, SubscriptionCommandBuilders.getInvoices);
        this.add(
            SubscriptionActions.getUpcomingInvoice.type,
            SubscriptionCommandBuilders.getUpcomingInvoice
        );
        this.add(
            SubscriptionActions.isUsersLimitReached.type,
            SiteCommandBuilders.isUserLimitReached
        );
        this.add(
            SubscriptionActions.changeSubscriptionQuantity.type,
            SubscriptionCommandBuilders.changeSubscriptionQuantity
        );
        this.add(
            SubscriptionActions.addAdditionalSubscriptions.type,
            SubscriptionCommandBuilders.addAdditionalSubscriptions
        );
        this.add(
            SubscriptionActions.removeAdditionalSubscription.type,
            SubscriptionCommandBuilders.removeAdditionalSubscription
        );

        // Register NOTIFICATIONS command builders
        this.add(
            NotificationActions.loadAccountLevelNotifications.type,
            NotificationCommandBuilders.loadAccountLevelNotifications
        );
        this.add(
            NotificationActions.loadSiteLevelNotifications.type,
            NotificationCommandBuilders.loadSiteLevelNotifications
        );
        this.add(
            NotificationActions.markNotificationRead.type,
            NotificationCommandBuilders.markNotificationRead
        );
        this.add(
            NotificationActions.deleteNotification.type,
            NotificationCommandBuilders.deleteNotification
        );
        this.add(
            NotificationActions.sendNotification.type,
            NotificationCommandBuilders.sendNotification
        );

        // Register GL TABLE SETTINGS command builders
        this.add(
            GlTableSettingsActions.loadGlTableSettingsById.type,
            GlTableSettingsCommandBuilders.loadGlTableSettingsByIdCommand
        );
        this.add(
            GlTableSettingsActions.patchGlTableSettings.type,
            GlTableSettingsCommandBuilders.patchGlTableSettingsCommand
        );

        // Register USER LOGS command builders
        this.add(
            UserLogActions.loadUserLogsBySiteId.type,
            UserLogCommandBuilders.loadUserLogsBySiteId
        );
        this.add(
            UserLogActions.loadUserLogsByProjectId.type,
            UserLogCommandBuilders.loadUserLogsByProjectId
        );
        this.add(
            UserLogActions.loadUserLogsByProgramId.type,
            UserLogCommandBuilders.loadUserLogsByProgramId
        );

        // Register ADMIN command builders
        this.add(
            SubscriptionPlanActions.loadSubscriptionPlans.type,
            AdminCommandBuilders.loadSubscriptionPlans
        );
        this.add(
            SubscriptionPlanActions.addSubscriptionPlan.type,
            AdminCommandBuilders.addSubscriptionPlan
        );
        this.add(
            SubscriptionPlanActions.updateSubscriptionPlan.type,
            AdminCommandBuilders.patchSubscriptionPlan
        );
        this.add(
            SubscriptionPlanActions.deleteSubscriptionPlan.type,
            AdminCommandBuilders.deleteSubscriptionPlan
        );
    }
}
