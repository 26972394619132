import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MinuteSelectors } from 'app/core/store/minute';
import { selectAccountSiteMembership } from 'app/core/store/shared/account-mebership.selectors';
import { ProjectPermissions } from 'app/core/store/shared/models/shared-permissions.model';
import { ChangeRequest, ChangeRequestSelectors } from '../change-request';
import { ProgramSelectors } from '../program';
import { ProgramRoleSelectors } from '../program-role';
import { ProjectSelectors } from '../project';
import { ProjectPhase, ProjectPhaseSelectors } from '../project-phase';
import { ProjectRoleSelectors } from '../project-role';
import { SiteMember, SiteMemberSelectors } from '../resource';
import { getDocumentPermissionsMap } from '../shared/models/shared-permissions.model';
import { SiteSelectors } from '../site';
import { SiteRoleSelectors } from '../site-role';
import { StateEntry } from '../state-entry';
import { ProgramPermissions, SitePermissions } from './../shared/models/shared-permissions.model';
import { adapter, FileNodeSystemTag, LoadingStatus, State } from './document.store';
import { DocumentLevel, FileNode, FileNodeType, getDocumentExtension } from './models';

export const selectDocumentEntry = createFeatureSelector<State>(StateEntry.Document);

export const selectLoadingStatus = createSelector(
    selectDocumentEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const selectCurrentProjectDocumentsLoadingStatus = createSelector(
    selectDocumentEntry,
    ProjectSelectors.selectCurrentProjectId,
    (state, projectId) => state.projectDocumentsLoadingStatusMap[projectId]
);

export const selectCurrentProgramDocumentsLoadingStatus = createSelector(
    selectDocumentEntry,
    ProgramSelectors.selectCurrentProgramId,
    (state, programId) => state.programDocumentsLoadingStatusMap[programId]
);

export const selectCompletixDocumentTemplatesLoadingStatus = createSelector(
    selectDocumentEntry,
    (state) => state.completixDocumentTempalatesLoading
);

export const selectDocumentPreviewLoadingStatusMap = createSelector(
    selectDocumentEntry,
    (state) => state.previewBlobLoadingStatusMap
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectDocumentEntry);

export const selectAllExtendedDocuments = createSelector(
    selectAll,
    ProjectPhaseSelectors.selectAll,
    ChangeRequestSelectors.selectAll,
    SiteMemberSelectors.selectCurrentSiteMembers,
    ProjectRoleSelectors.selectAccountProjectRolesMap,
    ProgramRoleSelectors.selectProgramRolesMap,
    (documents, projectPhases, changeRequests, siteMembers, projectRolesMap, programRolesMap) =>
        getExtendedDocuments(documents, projectPhases, changeRequests, siteMembers).filter(
            (doc) => {
                switch (doc.level) {
                    case DocumentLevel.Site:
                        return true;

                    case DocumentLevel.Completix:
                        return true;

                    case DocumentLevel.Program:
                        return (
                            programRolesMap[doc.programId]?.permissions?.documents
                                ?.readLockedDocuments || !doc.isPrivate
                        );

                    case DocumentLevel.Project:
                        return (
                            projectRolesMap[doc.projectId]?.permissions?.documents
                                ?.readLockedDocuments || !doc.isPrivate
                        );
                }
            }
        )
);

export const selectObjectIdsReferredByDocuments = createSelector(
    selectAllExtendedDocuments,
    (docs) => {
        const objectIds = docs.map((d) => d.originObjectId).filter((id) => !!id);
        return new Set(objectIds);
    }
);

export const selectCurrentProjectDocuments = createSelector(
    selectAllExtendedDocuments,
    ProjectSelectors.selectCurrentProjectId,
    ProjectPhaseSelectors.selectAll,
    ChangeRequestSelectors.selectAll,
    MinuteSelectors.selectAll,
    SiteMemberSelectors.selectCurrentSiteMembers,
    (documents, projectId, projectPhases, changeRequests, minutes, siteMembers) => {
        const projectDocuments = documents.filter((doc) => doc.projectId === projectId);
        const extendedDocuments = getExtendedDocuments(
            projectDocuments,
            projectPhases,
            changeRequests,
            siteMembers
        );
        const minutesFolder = documents.find(
            (d) => d.systemTag === FileNodeSystemTag.MinutesLinked
        );
        if (!minutesFolder) return extendedDocuments;
        return [
            ...extendedDocuments,
            ...minutes
                .filter((m) => !m.isDocument)
                .map(
                    (minute) =>
                        new FileNode({
                            id: minute.id,
                            parentFolderId: minutesFolder.id,
                            ctxType: FileNodeType.CtxMinute,
                            name: minute.name,
                            extension: FileNodeType.CtxMinute.toLowerCase(),
                            forbiddenActions: {
                                create: true,
                                edit: true,
                                rename: true,
                                remove: true,
                                move: true,
                            },
                        })
                ),
        ];
    }
);

export const selectCurrentProgramDocuments = createSelector(
    selectAllExtendedDocuments,
    ProgramSelectors.selectCurrentProgramId,
    ProjectPhaseSelectors.selectAll,
    ChangeRequestSelectors.selectAll,
    SiteMemberSelectors.selectCurrentSiteMembers,
    (documents, programId, projectPhases, changeRequests, siteMembers) => {
        const programDocuments = documents.filter((doc) => doc.programId === programId);
        return getExtendedDocuments(programDocuments, projectPhases, changeRequests, siteMembers);
    }
);

export const selectCurrentSiteDocuments = createSelector(
    selectAllExtendedDocuments,
    SiteSelectors.selectCurrentSiteId,
    (documents, siteId) =>
        getExtendedDocuments(documents, [], [], []).filter(
            (d) => d.level === DocumentLevel.Site && d.siteId === siteId
        )
);

export const selectCurrentSiteDocumentChangeRequestForm = createSelector(
    selectAllExtendedDocuments,
    SiteSelectors.selectCurrentSiteId,
    (documents, siteId) =>
        documents.find(
            (d) => d.systemTag === FileNodeSystemTag.ChangeRequestForm && d.siteId === siteId
        )
);

export const selectCompletixDocumentChangeRequestForm = createSelector(
    selectAllExtendedDocuments,
    (documents) =>
        documents.find(
            (d) => d.systemTag === FileNodeSystemTag.ChangeRequestForm && d.isCompletixTemplate
        )
);

export const selectChangeRequestFormLoadingStatus = createSelector(
    selectDocumentEntry,
    (state) => state.changeRequestFormLoadingStatus
);

export const selectCopyCutOperation = createSelector(
    selectDocumentEntry,
    (state) => state.copyCutOperation
);

export const selectCurrentOpenedId = createSelector(
    selectDocumentEntry,
    (state) => state.currentOpenedNodeId
);

export const selectFileAutoSave = createSelector(
    selectDocumentEntry,
    (state) => state.fileAutoSaveEnabled
);

export const selectPreviewBlobMap = createSelector(
    selectDocumentEntry,
    (state) => state.previewBlobMap
);

export const selectCurrentSiteDocumentTemplates = createSelector(
    selectCurrentSiteDocuments,
    (documents: FileNode[]) => {
        const templates = documents.filter((doc) => doc.isTemplate && !doc.originObjectId);
        return getExtendedDocuments(templates, [], [], []);
    }
);

export const selectCurrentProjectDocumentTemplates = createSelector(
    selectCurrentProjectDocuments,
    (documents: FileNode[]) => {
        const templates = documents.filter((doc) => doc.isTemplate);
        return getExtendedDocuments(templates, [], [], []);
    }
);

export const selectAllDocuments = createSelector(
    selectAllExtendedDocuments,
    (documents: FileNode[]) => documents
);

export const selectDocumentById = (documentId: string) =>
    createSelector(selectAllExtendedDocuments, (documents: FileNode[]) =>
        documents.find((d) => d.id === documentId)
    );

export const selectCompletixDocumentTemplates = createSelector(
    selectAllExtendedDocuments,
    (documents: FileNode[]) =>
        documents.filter((doc) => doc.isCompletixTemplate && doc.isTemplate && !doc.originObjectId)
);

export const selectCurrentProjectDocumentsByParentSystemTag = (systemTag: FileNodeSystemTag) =>
    createSelector(selectCurrentProjectDocuments, (documents) => {
        const parent = documents.find((d) => d.systemTag === systemTag);
        return documents.filter((doc) => doc.parentFolderId === parent?.id);
    });

function getExtendedDocuments(
    documents: FileNode[],
    projectPhases: ProjectPhase[],
    changeRequests: ChangeRequest[],
    siteMembers: SiteMember[]
) {
    const systemNodes = [
        documents.find((n) => n.systemTag === FileNodeSystemTag.Linked),
        documents.find((n) => n.systemTag === FileNodeSystemTag.Templates),
    ].filter((node) => !!node);

    getDescendant(FileNodeSystemTag.Linked);
    getDescendant(FileNodeSystemTag.Templates);

    function getDescendant(nodeId: string) {
        documents.forEach((node) => {
            if (node.parentFolderId === nodeId) {
                systemNodes.push(node);
                getDescendant(node.id);
            }
        });
    }

    function isSystemNode(nodeId: string) {
        return !!systemNodes.find((node) => node.id === nodeId);
    }

    const originObjectsDict: { [systemTag: string]: any[] } = {
        [FileNodeSystemTag.ChangeRequestsLinked]: changeRequests,
        [FileNodeSystemTag.GatingLinked]: projectPhases,
    };
    return documents.map((document) => {
        const extension = getDocumentExtension(document);
        const modifiedNode = { ...document, extension };

        if (isSystemNode(modifiedNode.id) || modifiedNode.systemTag) return modifiedNode;

        const originObject = originObjectsDict[modifiedNode.systemTag]?.find(
            (obj) => obj.id === modifiedNode.originObjectId
        );
        const ownerResource = siteMembers.find((res) => res.id === modifiedNode.ownerSiteMemberId);
        return {
            ...modifiedNode,
            ownerName: originObject?.name || originObject?.title || ownerResource?.name,
        } as FileNode;
    }) as FileNode[];
}

export const selectDocumentPermissionsMapByProjectId = (projectId: string) =>
    createSelector(
        selectAllExtendedDocuments,
        ProjectRoleSelectors.selectAccountProjectRolesMap,
        selectAccountSiteMembership,
        SiteRoleSelectors.selectCurrentSitePermissions,
        (allDocuments, rolesMap, accountSiteMember, sitePermissions) => {
            const projectDocuments = allDocuments.filter((d) => d.projectId === projectId);
            const projectPermissions =
                rolesMap[projectId]?.permissions ?? sitePermissions?.projects;
            return getDocumentPermissionsMap(
                projectDocuments,
                accountSiteMember?.id,
                projectPermissions.documents,
                projectPermissions.ownDocuments
            );
        }
    );

export const selectCurrentProjectDocumentsPermissionsMap = createSelector(
    selectCurrentProjectDocuments,
    ProjectRoleSelectors.selectCurrentProjectPermissions,
    selectAccountSiteMembership,
    (documents, permissions: ProjectPermissions, accountSiteMember) =>
        getDocumentPermissionsMap(
            documents,
            accountSiteMember?.id,
            permissions.documents,
            permissions.ownDocuments
        )
);

export const selectCurrentProgramDocumentsPermissionsMap = createSelector(
    selectCurrentProgramDocuments,
    ProgramRoleSelectors.selectCurrentProgramPermissions,
    selectAccountSiteMembership,
    (documents, permissions: ProgramPermissions, accountSiteMember) =>
        getDocumentPermissionsMap(
            documents,
            accountSiteMember.id,
            permissions.documents,
            permissions.ownDocuments
        )
);

export const selectCurrentSiteDocumentsPermissionsMap = createSelector(
    selectCurrentSiteDocuments,
    SiteRoleSelectors.selectCurrentSitePermissions,
    selectAccountSiteMembership,
    (documents, permissions: SitePermissions, accountSiteMember) =>
        getDocumentPermissionsMap(
            documents,
            accountSiteMember?.id,
            permissions.templates?.documents,
            permissions.templates?.documents
        )
);
